import Footer from "components/blocks/footer/Footer";
import Header from "components/blocks/header/Header";
import { useEffect, useContext, useState } from "react";
import { AppContext } from "contexts/AppContext";
import { callAPI } from "utils/API";
import PostCard from "components/elements/PostCard";
import { Grid } from "@mui/material";
import Headline3 from "components/elements/Headline3";
import ButtonMedium1 from "components/elements/ButtonMedium1";
import { createUseStyles } from "react-jss";
import { getVideoType } from "components/elements/Video";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Headline1 from "components/elements/Headline1";
import ContentNormal from "components/elements/ContentNormal";
import Headline2 from "components/elements/Headline2";
import CountDownBlock from "components/blocks/countDownBlock/CountDownBlock";
import ButtonMedium4 from "components/elements/ButtonMedium4";
import HorizontalScrollBlock from "components/blocks/horizontalScrollBlock/HorizontalScrollBlock";
import { scrollToDiv } from "utils/general";
import Activity from "utils/Activity";
import moment from "moment";
import { translate } from "utils/Translate";

const jss = createUseStyles({
  contentWrapper: {
    paddingLeft: "10%",
    paddingRight: "10%",
    display: "flex",
    flexDirection: "column",
  },
  "@media screen and (min-width: 1280px)": {
    contentWrapper: {
      paddingLeft: 100,
      paddingRight: 100,
    },
  },
});

const maxLengthForNewsTitle = 50;
const maxLengthForNewsDescription = 75;

const PostList = (props) => {
  const {postType=""} = props
  const { BlogID } = useParams()
  const { menuData, accessibleBlogs, styleGuide  } = useContext(AppContext);
  const [latestPosts, setLatestPosts] = useState(null);
  const [loadMore, setLoadMore] = useState(4);
  const [competitions, setCompetitions] = useState([]) 
  const latestNewsSectionStyles = {
    marginTop: 100,
    marginBottom: 100,
    position: "relative",
  };
  const [selectedBlog, setSelectedBlog] = useState(null)
  const navigate = useNavigate()

  if(BlogID && accessibleBlogs && accessibleBlogs.length) {
    let blogIds = accessibleBlogs.map((blog) => blog.BlogID)
    if(!blogIds.includes(Number(BlogID))) {
        navigate('/')
    }
  }  

  const fetchCompetition = async () => {
    const competitions = await callAPI(`/competitions`);
    let competitionsArr = []
    competitions && competitions.length && competitions.forEach(competition => {
      if(competition.competitionType === "COMPETITION") {
        competitionsArr.push(competition)
      }
    });
    setCompetitions(competitionsArr)
  }

  useEffect(() => {
    fetchCompetition()
  },[])

  useEffect(async () => {
    if (!accessibleBlogs || !accessibleBlogs.length) return setLatestPosts([])

    const accessibleBlogIDs = accessibleBlogs.filter(item => item.blogType === (postType === "taxCampaign" ? 'TAX CAMPAIGN' : 'NEWS')).map((item) => item.BlogID);
    if (!accessibleBlogIDs || !accessibleBlogIDs.length) return setLatestPosts([])
    
    const blogId = Number(BlogID) || accessibleBlogIDs[0]
    setSelectedBlog(blogId)
    const isPathBlogIDAccessible = !!blogId && accessibleBlogIDs.includes(Number(blogId))
    if(!!blogId && !isPathBlogIDAccessible) return setLatestPosts([])

    const BlogIDs = isPathBlogIDAccessible ? [blogId] : accessibleBlogIDs
    const sortBy = "timeStart";
    const sortOrder = "DESC";
    const latestPosts = await callAPI(
      "/post/getPostsOfMultipleBlogs",
      "GET",
      { query: { BlogIDs, sortBy, sortOrder } }
    );
    if (latestPosts && latestPosts.length > 0) {
      setLatestPosts(latestPosts);
    }
  }, [accessibleBlogs, BlogID]);

  const isVideo = (attachments) => {
    const videoAttachment = attachments.filter(
      (item) => item.LinkID && item.linkRef
    );
    return videoAttachment.length ? true : false;
  };

  const getVideoLink = (attachments) => {
    const videoAttachment = attachments.filter(
      (item) => item.LinkID && item.linkRef
    );
    const link = videoAttachment.length ? videoAttachment[0].linkRef : null;
    const videoInfo = getVideoType(link);
    return videoInfo ? videoInfo.url : null;
  };

  const goToBlog = (id) => Number(BlogID) === id ? navigate('/posts') : navigate(`/posts/${id}`)
  
  const location = useLocation()
  useEffect(() => {
    if (location.pathname === '/competitions') {
      const element = document.getElementById("competitionsDiv")
      element.scrollIntoView({
        behavior: "smooth",
        alignToTop: true,
      });
    }
  })

  let blogHeading
  let blogSubCaption

  if (!!menuData?.length) {
    let blogMenuItem = menuData.filter(menu => menu.poolName.toLowerCase() === 'blog')
    if (!!blogMenuItem || blogMenuItem.length) {
      console.log(blogMenuItem)
      blogHeading = postType === "taxCampaign" ? translate("_TAX_CAMPAIGN_POSTS_CAPTION_", "Tax campaigns") : blogMenuItem[0].menuTitle || translate("_NEWS_PAGE_CAPTION_", "Nyhedsarkiv")
      blogSubCaption = postType === "taxCampaign" ? translate("_TAX_CAMPAIGN_POSTS_SUB_CAPTION_", " ") : translate("_NEWS_PAGE_SUB_CAPTION_", "Her kan du læse vores nyheder")
    }
  }

  return (
    <>
      <div style={latestNewsSectionStyles}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginBottom: 80 }}>
          <Headline1 fallbackText={blogHeading} />
          <ContentNormal fallbackText={blogSubCaption} />
        </div>

        {postType !== "taxCampaign" && accessibleBlogs.length > 1 &&
          <div style={{ width: '100%', background: styleGuide.color18, display: 'flex', padding: '20px', height: 50, justifyContent: "center" }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 50, alignItems: "center" }}>
              {
                accessibleBlogs.filter(blog => blog.blogType === "NEWS").map(blog => <div key={blog.BlogID} 
                    style={{ color: styleGuide.color1, cursor: "pointer", fontWeight: selectedBlog === blog.BlogID ? "bold" : "normal", textTransform: 'capitalize' }} 
                    onClick={() => goToBlog(blog.BlogID)}
                    >
                      {blog.moduleName}
                    </div>
                  )
              }
            </div>
          </div>
        }
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6} style={{ marginBottom: 30 }}>
          </Grid>
          <Grid item xs={12} md={6}>
            <div></div>
          </Grid>
          {latestPosts && !!latestPosts.length && latestPosts.slice(0,loadMore).sort((a,b) => a.poolOrder - b.poolOrder).map((post) => {
            post.attachments.sort((a, b) => a.poolOrder - b.poolOrder);
            const cardImage = post.cardImage ? post.cardImage : (post.attachments.length && post.attachments[0].imageOriginSource)
              ? post.attachments[0].imageOriginSource
              : "https://i.imgur.com/qtmXhnZ.jpg"
            const video =
              !!post.videoSrc ? null :
              post.attachments.length && isVideo(post.attachments)
                ? getVideoLink(post.attachments)
                : null;

            return (
              <Grid item xs={12} md={6} key={post.PostID}>
                <PostCard
                  onClick={() => {
                    Activity({activityType: 'click',activityName: 'BlogPosts',activityArgument: 'Post',activityArgumentID: post.PostID})
                    navigate(`/${postType === "taxCampaign" ? 'postcampaign' : 'post'}/${post.PostID}`)}}
                  style={{marginBottom: '25px'}}
                  title={
                    post.elementTitle.length > maxLengthForNewsTitle
                      ? post.elementTitle.substr(0, maxLengthForNewsTitle - 3) +
                        "..."
                      : post.elementTitle || ""
                  }
                  description={
                    post.elementContent.substr(
                      0,
                      maxLengthForNewsDescription - 3
                    ) + "..." || ""
                  }
                  imageSrc={`url(${cardImage})`}
                  type={video ? "video" : "image"}
                  videoLink={video}
                />
              </Grid>
            );
          })}
        </Grid>

        {Number(loadMore) < Number(latestPosts && latestPosts.length) && (
          <div>
            <ButtonMedium1
              onClick={() => setLoadMore(loadMore + 4)}
              style={{ margin: "20px auto" }}
              showIcon={false}
              translationKey="_HOME_NEWS_LOAD_MORE_BUTTON_"
            >
              Indlæs flere
            </ButtonMedium1>
          </div>
        )}
      </div>
      <div id="competitionsDiv">
        {competitions && competitions.length ? <CompetitionsBlock competitions={competitions} /> : null}
      </div>
    </>
    );
};

export const CompetitionsBlock = (props) => {
  const contextState = useContext(AppContext);
  const {
    styleGuide,
  } = contextState;
  const {competitions} = props
  const classes = jss()
  const navigate = useNavigate()
  const [seeAllCompetitions, setSeeAllCompetitions] = useState(false)

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20, marginTop: 100, marginBottom: 100 , position: 'relative'}}>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Headline2 translationKey="_NEWS_PAGE_COMPETITION_" fallbackText="Aktuelle konkurrencer" />
        </div>
        {
          competitions && competitions.length < 2 || seeAllCompetitions ?
          <>
            {competitions.map((competition, idx) => (
              <CountDownBlock key={idx} competition={competition} styles={{marginTop: 50}} buttonTitle="Deltag i konkurrencen"/>
            ))}
          </> :
          <div className="countDownHorizontalItems">
            <HorizontalScrollBlock>
              {competitions.map((competition, idx) => (
                <CountDownBlock key={idx} competition={competition} buttonTitle="Deltag i konkurrencen"/>
              ))}
            </HorizontalScrollBlock>
          </div>
        }
      </div>
      <div className={classes.seeMoreButton}>
        {seeAllCompetitions ? null : <ButtonMedium4
          style={{ margin: "auto" }}
          showIcon={false}
          translationKey="_HOME_COMPETITION_SEE_MORE_BUTTON_"
          onClick={() => setSeeAllCompetitions(!seeAllCompetitions)}
        >
          Se alle konkurrencer
        </ButtonMedium4>}
      </div>
    </div>
  )
}

export default PostList;
const ContentWrapper = (props) => {
  const classes = jss();
  return <div className={classes.contentWrapper}> {props.children} </div>;
};
