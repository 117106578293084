import { useContext, useEffect, useRef, useState } from 'react'
import { AppContext } from 'contexts/AppContext'
import './Product.css'
import CustomCarousel from 'components/blocks/customCarousel/CustomCarousel'
import Image from 'components/elements/Image'
import ArrowRight from 'components/icons/ArrowRight'
import ArrowLeft from 'components/icons/ArrowLeft'
import Headline2 from 'components/elements/Headline2'
import Headline6 from 'components/elements/Headline6'
import ContentSmall from 'components/elements/ContentSmall'
import IconButtonLarge from 'components/elements/IconButtonLarge'
import Heart2 from 'components/icons/Heart2'
import ButtonLarge1 from 'components/elements/ButtonLarge1'
import ButtonInactive from 'components/elements/ButtonInactive'
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled from '@mui/base/TabUnstyled';
import { createUseStyles } from 'react-jss'
import ContentNormal from 'components/elements/ContentNormal'
import Translate, { translate } from 'utils/Translate'
import Cart1 from 'components/icons/Cart1'
import ProductLarge from 'components/elements/ProductLarge'
import HorizontalScrollBlock from 'components/blocks/horizontalScrollBlock/HorizontalScrollBlock'
import ProductNormal from 'components/elements/ProductNormal'
import { useNavigate, useParams } from 'react-router-dom'
import ButtonMedium4 from 'components/elements/ButtonMedium4'
import _ from 'lodash'
import { callAPI } from "utils/API";
import { parseLinks, pointsConvertor } from 'utils/general'
import { Grid } from '@mui/material'
import CaptionText from 'components/elements/CaptionText'
import Tick from 'components/icons/Tick'
import Close from 'components/icons/Close'
import { addProductToBasket, removeProductFromBasket, updateProductQuantityInBasket } from 'utils/basket'
import { callTagManagerEvent, setupBasketData } from 'utils/setup'
import ExplainerText from 'components/elements/ExplainerText'
import ExplainerTextTiny from 'components/elements/ExplainerTextTiny'
import ButtonMedium1 from 'components/elements/ButtonMedium1'
import Playbutton from "components/icons/Playbutton"
import Pausebutton from "components/icons/Pausebutton"
import {Modal} from "react-bootstrap"
import { TaxPercentageForm } from 'components/blocks/taxPercentageForm/TaxPercentageForm'
import { debounce } from 'utils/debouce'
import FreightInfo from 'modules/basket/FreightInfo'

const jss = createUseStyles({
    TabStyle: {
        color: (styleGuide) => styleGuide.color16,
        '&:hover': {
            backgroundColor: (styleGuide) => styleGuide.color2,
        },
        '&:focus': {
            color: (styleGuide) => styleGuide.color7,
        },
        '&.Mui-selected': {
            backgroundColor: (styleGuide) => styleGuide.color3,
            color: (styleGuide) => styleGuide.color7,
        }
    },
    InactiveButtonStyle: {
        backgroundColor: '#e8e8e8',
        borderRadius: (styleGuide) => styleGuide.buttonBorderRadius,
        height: (styleGuide) => styleGuide.buttonLargeHeight,
        fontSize: (styleGuide) => styleGuide.buttonLargeFontSize,
        fontFamily: (styleGuide) => styleGuide.secondaryFontFamily,
        lineHeight: (styleGuide) => styleGuide.buttonLargeLineHeight,
        fontWeight: '600',
        border: 'none',
        padding: '14px 16px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'not-allowed'
    }
})

const Product3 = (props) => {
    const {checkBasketForOnePointUser, freightInfo} = props
    const defaultCarouselInterval = 1000
    const { styleGuide, accessibleShops, basket, userData, setBasket, brandData, langCode } = useContext(AppContext)
    const classes = jss(styleGuide)
    const [viewMore, setViewMore] = useState(false);
    const [productAddedToCart, setproductAddedToCart] = useState(false);
    const [interval, setinterval] = useState(defaultCarouselInterval);
    const [activeIndex, setactiveIndex] = useState(null);
    const [showProductSize, setShowProductSize] = useState(false);
    const [showVariation, setShowVariations] = useState(false);

    const [contentContainerHeight, setContentContainerHeight] = useState(0);
    const [recentlyViewedProducts, setRecentlyViewedProducts] = useState([])
    const [videoHeight,setVideoHeight]=useState()
    const [relatedProducts, setRelatedProducts] = useState([])
    const [productDetails, setproductDetails] = useState({})
    const [ShopID, setShopID] = useState(accessibleShops && accessibleShops.length > 0 ? accessibleShops[0].ShopID : null)
    const [productVariations, setproductVariations] = useState([])
    
    const [variationSizes, setVariationSizes] = useState([])
    const [selectedSize, setselectedSize] = useState(null)

    const [variationColors, setVariationColors] = useState([])
    const [selectedColor, setselectedColor] = useState(null)

    const [variationTitles, setVariationTitles] = useState([])
    const [selectedTitle, setselectedTitle] = useState(null)
    
    const [selectedVariation, setselectedVariation] = useState(null)
    const [selectedVariationInBasket, setselectedVariationInBasket] = useState(null)
    const [selectedVariationStock, setselectedVariationStock] = useState(null)
    const [play, setPlay] = useState(true);
    const navigate = useNavigate();
    const detailsImagePositionTop = brandData?.features?.headerBackground && brandData.features.headerBackground.toLowerCase() === 'transparent' ? 0 : 80;
    const ShopIDs = accessibleShops.map(item => item.ShopID)
    const { ProductID } = useParams()
    const videoRef = useRef();

    const [errorMessage, seterrorMessage] = useState(null);
    const [showError, setShowError] = useState(false);
   
    const imageHeight = 720 // 900
    const productPrice = `10.418 pt`
    const summaryContentLength = 200
    const price = (productDetails.discountPointComputed ? productDetails.discountPointComputed : productDetails.productPointComputed) 
    const pointSuffix = translate("_POINTS_SHORT_", "pt")
    const taxProduct = userData.taxPercentage ? Math.ceil(price/(brandData.brandPointRatio || 1) * userData.taxPercentage/100) : null
    const basketHasRoomforMoreProducts = (basket, accessibleShops) => {
        let currentShop = accessibleShops.filter(e => e.ShopID === ShopID)
        let productShopSelectionLimit = currentShop && currentShop.length ? currentShop[0].shopSelectionLimit : 0 ;
        if (productShopSelectionLimit === 0) {
            return true
        } 
        if (productShopSelectionLimit > 0 && currentShop.length > 0) {
            const basketShop = basket.filter(basketItem => basketItem.ShopID === currentShop[0].ShopID)
            if (basketShop && basketShop.length >= productShopSelectionLimit) {
            return false
            }
        }
        return true
      }

    const resetVariationDetails = () => {
        setinterval(defaultCarouselInterval)
        setactiveIndex(null)
        setselectedVariation(null)
        setselectedVariationInBasket(null)
        setselectedVariationStock(null)
        setselectedSize(null)
        setselectedColor(null)
        setselectedTitle(null) 
        setproductAddedToCart(false)
    }

    const fetchProductDetails = async () => {
        if (!ProductID || !ShopIDs) return
        let productInfo = await callAPI(`/product/${ProductID}`, "GET", { query: { ShopIDs } });
        if (productInfo && !!productInfo.length) {
            productInfo[0].images = productInfo[0].images.map(image => {
                productInfo[0].imageSrc = image.imageOriginSource
                productInfo[0].imageBackgroundColor = image.backgroundColor
                return image
            });
            setproductDetails(productInfo[0])
            assignVariations(productInfo[0])
        }
        
        return
    }

    const assignVariations = async (product) => {
        if(!product || !product.variations || !product.variations.length) {
            setproductVariations([])
            return
        }
        product.variations.forEach(variation => {
            variation.ImageID = variation.images && variation.images.length ? variation.images.map(img => img.ImageID)[0] : null
        });
        setproductVariations(product.variations)
        let sizes = []
        let colors = []
        let titles = []
        product.variations.sort((a,b) => a.poolOrder - b.poolOrder).map(vr => {
            const available = !vr.variationStockable || !!vr.stockOverride ? true : !vr.variationStock ? false : true
            if(!sizes.filter(v => v.variant === vr.variationSize).length) !!vr.variationSize && sizes.push({variant: vr.variationSize, available })
            if(!colors.filter(v => v.variant === vr.colorHexcode).length) !!vr.colorHexcode && colors.push({variant: vr.colorHexcode, available})
            if(!titles.filter(v => v.variant === vr.variationTitle).length) !!vr.variationTitle && titles.push({variant: vr.variationTitle, available})
        })
        setVariationSizes(sizes)
        setVariationColors(colors)
        setVariationTitles(titles)
        if(product.variations.length === 1) {
            setselectedVariation(product.variations[0])
        }
        const productPrice = product.discountPointComputed ? product.discountPointComputed : product.productPointComputed
        // callKlaviyoClientAPI(userData, "Viewed Product", {ProductID: product.ProductID, VariationID: product.variations[0].VariationID, productPrice}, productPrice)
        callTagManagerEvent({
            dataLayer: {
              event: 'view_item',
              ecommerce: {
                currency: 'DKK',
                value: productPrice,
                items: [{
                    item_name: product.elementTitle,
                    item_id: product.ProductID,
                    item_brand: product.manufacturer,
                    item_category: '',
                    item_variant: '',
                    quantity: 1,
                    price: productPrice
                }]
              }
            },
        });
        return
    }

    const fetchRelatedProducts = async () => {
        if (ShopID) {
            let relatedProducts = await callAPI(`/product/popularProducts`, "GET");
            if (relatedProducts && !!relatedProducts.length) {
                relatedProducts.forEach(product => {
                    if(product.images && product.images.length){
                        product.images.sort((a,b) => a.poolOrder - b.poolOrder);
                        product.imageSrc = product.images[0].imageOriginSource;
                        product.imageBackgroundColor = product.images[0].backgroundColor;
                    }
                });
                setRelatedProducts(relatedProducts)
                return
            }
        }
    }

    const fetchRecentlyViewedProducts = async () => {
        let recentlyViewedProducts = await callAPI(`/product/recentlyViewedProducts`, "GET");
        if (recentlyViewedProducts && !!recentlyViewedProducts.length) {
            recentlyViewedProducts.forEach(product => {
                product.images = product.images.forEach(image => {
                    product.imageSrc = image.imageOriginSource
                    product.imageBackgroundColor = image.backgroundColor
                    return image
                });
            });
            setRecentlyViewedProducts(recentlyViewedProducts.filter(p => p.ProductID !== +ProductID))
            return
        }
    }

    const productReaction = async (product) => {
        const ReactionID = product.reaction ? product.reaction.ReactionID : null
        await callAPI("/product/reaction", "POST", { body: { ReactionTargetID: product.ProductID, reactionData: JSON.stringify({ ShopID: product.ShopID }), ...ReactionID && { ReactionID } } });
        fetchProductDetails()
        fetchRelatedProducts()
        fetchRecentlyViewedProducts()
        await setupBasketData({userData, setBasket})
    }

    const isDisabled = () => ( variationSizes.length ? selectedSize ? false : true : false ) || (variationColors.length ? selectedColor ? false : true : false) || (variationTitles.length && !variationSizes.length && !variationColors.length ? selectedTitle ? false : true : false )

    const addToCart = debounce(async () => {
        if(!checkBasketForOnePointUser()) return 

        if(!selectedVariation) return seterrorMessage(<Translate translationKey='VARIATION_NOT_SELECTED'>Du mangler at vælge variation. </Translate>)
        if(!selectedVariation.stockOverride && selectedVariation.variationStockable === 1 && selectedVariation.variationStock < 1) return seterrorMessage(<Translate translationKey='VARIATION_NOT_IN_STOCK'>Et eller flere produkter i din kurv er ikke på lager længere.</Translate>)
        if(isDisabled()) {
            ((variationSizes.length && !selectedSize) || (variationColors.length && !selectedColor)) 
                ? setShowProductSize(true)
                : setShowVariations(true)
            return
        }

        setproductAddedToCart(true)
        // callKlaviyoClientAPI(userData, "Add to cart", {ProductID: productDetails.ProductID, VariationID: selectedVariation.VariationID, productPrice: price}, price)
        callTagManagerEvent({
            dataLayer: {
              event: 'add_to_cart',
              ecommerce: {
                currency: 'DKK',
                value: price,
                items: [{
                    item_name: productDetails.elementTitle,
                    item_id: productDetails.ProductID,
                    item_brand: productDetails.manufacturer || '',
                    item_category: productDetails.categories?.length ? productDetails.categories.map(c => c.categoryName).join() : '',
                    item_variant: selectedVariation.variationTitle,
                    quantity: 1,
                    price: productDetails.productPointComputed
                }]
              }
            },
        });
        
        if(productDetails.enableMulti === 1 && selectedVariation.VariationID) {
            await addProductToBasket(productDetails.ShopID, productDetails.ProductID, selectedVariation.VariationID, productDetails.minCount)
            setupBasketData({userData, setBasket})
            return
        }
        const basketActionResponse = !selectedVariationInBasket
            ? await addProductToBasket(productDetails.ShopID, productDetails.ProductID, selectedVariation.VariationID, 1)
            : await updateProductQuantityInBasket(selectedVariationInBasket.BasketID, selectedVariationInBasket.amount+1)

        setupBasketData({userData, setBasket})
    }, 1000)

    useEffect(() => {
        if (!!errorMessage) return setShowError(true)
        setShowError(false)
    }, [errorMessage])

    const variationAlreadyInBasket = () => {
        if(!selectedVariation) return null

        const existing = basket.filter(item => item.ProductID === productDetails.ProductID && item.VariationID === selectedVariation.VariationID)
        return existing.length ? existing[0] : null
    }

    const showBasketCard = () => {
        if(!productAddedToCart) return
        setTimeout(() => {
            document.getElementById('basket-item-card-slide') && document.getElementById('basket-item-card-slide').classList.add('show');
            setTimeout(() => {
                document.getElementById('basket-item-card-slide') && document.getElementById('basket-item-card-slide').classList.remove('show');
            }, 10000)
        }, 1)
    }

    const playButton = () => {
        let pause_time = document.getElementById("pause_button_time");
        if (play) {
            videoRef.current.play();
            setPlay(false);
            setTimeout(() => {
            pause_time.style.color = "transparent";
            },5000)
        }
        else {
            videoRef.current.pause();
            pause_time.style.color="white";
            setPlay(true)
        }
    }

    const productTitle = !!productDetails && !!productDetails.translations && !!productDetails.translations.length > 0
        ? productDetails.translations.find(
                (translation) => translation.languageKey === langCode
        ) 
        ? productDetails.translations.find(
            (translation) => translation.languageKey === langCode
        ).elementTitle
            : productDetails.elementTitle
        : productDetails.elementTitle

    const productDescription = !!productDetails && !!productDetails.translations && !!productDetails.translations.length > 0
        ? productDetails.translations.find(
                (translation) => translation.languageKey === langCode
        ) 
        ? productDetails.translations.find(
            (translation) => translation.languageKey === langCode
        ).elementContent
            : productDetails.elementContent
        : productDetails.elementContent

    const closeBasketCard = () => document.getElementById('basket-item-card-slide') && document.getElementById('basket-item-card-slide').classList.remove('show');

    const BasketItemCard = (props) => {
        const navigate = useNavigate()
        if(!selectedVariation) return null
        
        const imageUrl = selectedVariation.images && selectedVariation.images.length && selectedVariation.images[0].imageOriginSource || productDetails.imageSrc
        return  <>
            <div id='basket-item-card-slide' className="basket-card" style={{ backgroundColor: styleGuide.color1, overflow: 'hidden'}}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', padding: '20px 20px 0 20px', justifyContent: 'flex-end' }}>
                            <Close style={{ height: 13, width: 13, cursor: 'pointer' }} onClick={closeBasketCard} />
                    </div>
                        <div style={{ padding: '0 40px' }}>
                            <Headline6 translationKey="_ADDED_TO_CART_" fallbackText="Tilføjet til kurven" />
                    </div>
                        <div style={{ display: 'flex', gap: '1rem', padding: '20px  35px 20px 35px' }} >
                            {!!imageUrl && <div style={{display: 'flex' }}>
                                <Image style={{ height: 100, width: 80 }} src={`url(${imageUrl})`} />
                            </div>
                            }
                            <div style={{width:"100%"}}>
                            <div style={{display: 'flex', justifyContent: 'space-between', flex: 1 }}>
                             <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                                    { productDetails.manufacturer && <ExplainerText fallbackText={productDetails.manufacturer} /> }
                                    { productTitle && <ExplainerTextTiny style={{ color: styleGuide.color5 }}>{productTitle + (!!productDetails.enableMulti ? ', ' + productDetails.minCount + ' stk' : '')}</ExplainerTextTiny> }
                                    <div style={{display: 'flex', marginTop: '0.25rem', alignItems: 'center'}}>
                                        {
                                            !!selectedVariation && ((selectedVariation.colorHexcode && selectedVariation.variationSize) || selectedVariation.variationTitle) && <>
                                                <ExplainerText 
                                                    style={{ color: styleGuide.color5 }} 
                                                    fallbackText={selectedVariation.variationSize ? `${selectedVariation.variationSize}` : `${selectedVariation.variationTitle}`}
                                                />
                                                { 
                                                    selectedVariation.colorName 
                                                        ? <ExplainerText style={{ color: styleGuide.color5 }} fallbackText={`, ${selectedVariation.colorName}`} />
                                                        : null 
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                <div style={{width:"40%",display: 'flex',flexDirection: 'column', alignItems: 'flex-end'}}>
                                    {!brandData?.features?.disablePrice && <CaptionText
                                        style={{ color: styleGuide.color16 }}
                                        fallbackText={pointsConvertor(brandData,
                                            (productDetails.enableMulti && productDetails.minCount > 0) 
                                            ? `${productDetails.minCount * price}` 
                                            : `${price}`, pointSuffix)} />}
                                </div>
                                </div>
                                <div style={{float:'right'}}>
                                {
                                    (!productDetails.reaction || !productDetails.reaction.ReactionID) && !brandData?.features?.disableWishlist && 
                                        <div onClick={() => {
                                            productReaction(productDetails)
                                            closeBasketCard()
                                            const basketItem = basket.filter(item => item.VariationID === selectedVariation.VariationID)
                                            if(basketItem.length) {
                                                removeProductFromBasket(basket[0].BasketID)
                                                setupBasketData({userData, setBasket})
                                            }
                                            }} style={{cursor: 'pointer',justifyContent: 'right',}}>
                                            <ExplainerText translationKey="_MOVE_TO_WISHLIST_" fallbackText="Flyt til ønskelisten" />
                                        </div>
                                }
                                </div>
                                </div>
                            </div>
                        <div className='basket_buttons' style={{ display: 'flex', backgroundColor: styleGuide.color2, padding: '22px  40px', justifyContent: 'space-between' }}>
                            <ButtonMedium4 style={{ padding: '0 10px' }}  showIcon={false} translationKey="_CONTINUE_SHOPPING_" fallbackText="Shop videre"  onClick={closeBasketCard} />
                            <ButtonMedium1 style={{ padding: '0 10px' }}  showIcon={false} translationKey="_GO_TO_BASKET_" fallbackText="Gå til kurven"  onClick={() => {
                                navigate('/basket')}} />
                        </div>
                    </div>
                </div>
        </>
    }
    

    useEffect(() => {
        const productContentContainer = document.getElementById('product-container')
        const heightOfContent = !!productContentContainer ? productContentContainer.offsetHeight : 0
        setContentContainerHeight(heightOfContent)
        if(!!productDetails && productDetails.ShopID) setShopID(productDetails.ShopID)
    }, [viewMore, productDetails, variationColors, variationSizes, variationTitles, selectedVariationStock]);

    useEffect(() => {
        let videoWidth = window.screen.width
        setVideoHeight(videoWidth/2)
        window.addEventListener('resize', _.debounce(() => {
            const productContentContainer = document.getElementById('product-container')
            const heightOfContent = !!productContentContainer ? productContentContainer.offsetHeight : 0
            setContentContainerHeight(heightOfContent)
        }, 200));
        return () => {
            window.removeEventListener('resize', _.debounce(() => {
                const productContentContainer = document.getElementById('product-container')
                const heightOfContent = !!productContentContainer ? productContentContainer.offsetHeight : 0
                setContentContainerHeight(heightOfContent)
            }, 200));
        }
    }, []);

    useEffect(() => {
        window.addEventListener('resize', _.debounce(() => {
            const videoContainer = document.getElementById('video-div')
            const videoheight = !!videoContainer ? videoContainer.offsetHeight : 650
            setVideoHeight(videoheight)
            
        }, 200));
        return () => {
            window.removeEventListener('resize', _.debounce(() => {
                const videoContainer = document.getElementById('video-div')
                const videoheight = !!videoContainer ? videoContainer.offsetHeight : 650
                setVideoHeight(videoheight)
            }, 200));
        }
    },[productDetails])

    useEffect(() => {
        resetVariationDetails()
        fetchProductDetails()
        fetchRelatedProducts()
        fetchRecentlyViewedProducts()
    }, [ProductID, accessibleShops]);

    useEffect(() => {
        if(isDisabled()) {
            setselectedVariation(null)
            return
        }

        const variationByTitle = selectedTitle ? productVariations.filter(item => item.variationTitle === selectedTitle) : []
        const variationBySize = selectedSize ? productVariations.filter(item => selectedColor ? item.variationSize === selectedSize && item.colorHexcode === selectedColor : item.variationSize === selectedSize ) : []
        const variationByColor = selectedColor ? productVariations.filter(item => selectedSize ? item.variationSize === selectedSize && item.colorHexcode === selectedColor : item.colorHexcode === selectedColor) : []
        
        if(variationBySize.length === 1) {
            setselectedVariation({...variationBySize[0]})
            return
        }

        if(variationByColor.length === 1) {
            setselectedVariation({...variationByColor[0]})
            return
        }

        if(variationByTitle.length) {
            setselectedVariation({...variationByTitle[0]})
            return
        }
        
        setselectedVariation(null)

    }, [selectedSize, selectedTitle, selectedColor]);

    useEffect(() => {
        if(!selectedVariation) {
            setselectedVariationStock(null)
            return
        }
        setselectedVariationStock(!selectedVariation.stockOverride && selectedVariation.variationStockable && selectedVariation.variationStock >= 0 ? `${selectedVariation.variationStock} stk. på lager` : null)

        setselectedVariationInBasket(variationAlreadyInBasket())
    }, [selectedVariation]);

    useEffect(() => {
        const exist = variationAlreadyInBasket()
        setselectedVariationInBasket(exist)
        const displayCard = exist ? showBasketCard() : closeBasketCard()
    }, [basket]);

    useEffect(() => {
        if (productDetails?.video) {
            setTimeout(() => {
            videoRef.current.play();
            videoRef.current.pause();
        }, 1000);
    }
    }, [productDetails])

    const ProductSize = () => (
        <Modal show={showProductSize} onHide={() => setShowProductSize(false)} style={{ margin: 'auto' }} centered>
        <Modal.Header className="pb-0 border-0" closeButton style={{ fontSize: 9 }}>
         <Headline6 translationKey="_CHOOSE_SIZE_">Vælg</Headline6>
        </Modal.Header>
       <Modal.Body className="pt-0">
          <ContentSmall translationKey="_CHOOSE_SIZE_TEXT_">Enten “størrelse” eller “farve” eller begge er ikke valgt</ContentSmall>
          <div style={{ 
            display: 'flex',
            float:'right',
           padding: '1rem',
          }}>
           <ButtonMedium1 showIcon={false} translationKey="_OK_" fallbackText="OK" onClick={() => setShowProductSize(false)} />
         </div>
       </Modal.Body>
     </Modal>
      )

      const ProductVariation = () => (
        <Modal show={showVariation} onHide={() => setShowVariations(false)} style={{ margin: 'auto' }} centered>
        <Modal.Header className="pb-0 border-0" closeButton style={{ fontSize: 9 }}>
         <Headline6 translationKey="_CHOOSE_VARIATION_">Vælg</Headline6>
        </Modal.Header>
       <Modal.Body className="pt-0">
          <ContentSmall translationKey="_CHOOSE_VARIATION_TEXT_">Ingen variationer valgt</ContentSmall>
          <div style={{ 
            display: 'flex',
            float:'right',
           padding: '1rem',
          }}>
           <ButtonMedium1 showIcon={false} translationKey="_OK_" fallbackText="OK" onClick={() => setShowVariations(false)} />
         </div>
       </Modal.Body>
     </Modal>
      )
    if (isNaN(ProductID) || !productDetails || !productDetails.ProductID) return null
    return <>
        <div style={{ display: 'flex', flexDirection: 'column', zIndex: 0 }}>
            <div className='product-container' id='product-container' style={{ position: 'absolute', top: detailsImagePositionTop, left: 0, right: 0 }}>
                <div className='product-image-container'>
                    <CustomCarousel
                        id={`product-images ${interval}`}
                        activeIndex={activeIndex}
                        style={{ position: 'sticky', top: 0 }}
                        interval={interval}
                        indicators={true}
                        nextIcon={<ArrowRight fill={styleGuide.color1} />}
                        prevIcon={<ArrowLeft fill={styleGuide.color1} />}
                        children={
                            productDetails.images.sort((a,b) => a.poolOrder - b.poolOrder).map(image => <Image
                                className='product-details-image'
                                style={{backgroundColor: image.backgroundColor || styleGuide.productImageBackgroundColor }}
                                key={image.ImageID}
                                src={`url(${image.imageOriginSource})`} />
                            )
                        }
                    />
                </div>
                <div className='product-details-container'>
                    <div className='product-details-section'>
                        <Headline2 style={{ marginTop: 48, color: styleGuide.color7 }}>{productTitle + (!!productDetails.enableMulti ? ', ' + productDetails.minCount + ' stk' : '')}</Headline2>
                        <Headline6 style={{ marginTop: 10, color: styleGuide.color6 }}>{productDetails.manufacturer || <></>}</Headline6>
                        {/* <ContentSmall skipTranslation={true} style={{ marginTop: 30, color: styleGuide.color6 }}>{productSummary}</ContentSmall> */}
                        <div className='product-variations' style={{marginTop: 20, marginBottom: 48}}>
                            <Grid container>
                                {
                                    !!variationSizes.length && <Grid item xs={12} sm={6} >
                                        <CaptionText style={{color: styleGuide.color6}} translationKey="_PRODUCT_VARIATION_SIZE_LABEL_" fallbackText="Størrelser" /> 
                                        <div style={{display: 'flex', flex: 0.5, gap: '0.5rem', marginTop: 10, flexWrap: 'wrap'}}>
                                            {
                                                variationSizes.map((item,idx) => {
                                                    const allowSelection = selectedSize === item.variant ? true : selectedColor ? productVariations.filter(v => v.variationSize === item.variant && v.colorHexcode === selectedColor).length ? true : false : true
                                                    return(
                                                        !!item.variant &&
                                                        <div key={idx} 
                                                            className='size-variation'
                                                            onClick={()=> allowSelection ? setselectedSize(selectedSize === item.variant ? null : item.variant) : null }
                                                            style={{border: (selectedSize === item.variant) ? `0.1px solid ${styleGuide.color16}` : `0.1px solid ${styleGuide.color4}`,}}
                                                        >
                                                            <CaptionText style={{color: !!allowSelection ? styleGuide.color7 : styleGuide.color5, fontWeight: (selectedSize === item.variant) && "600"}}>{item.variant}</CaptionText>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Grid>
                                }
                                {
                                    !!variationColors.length && <Grid item xs={12} sm={6}>
                                        <CaptionText style={{color: styleGuide.color6}} translationKey="_PRODUCT_VARIATION_COLOR_LABEL_" fallbackText="Farver" /> 
                                        <div style={{display: 'flex', flex: 0.5, gap: '0.5rem', marginTop: 10, flexWrap: 'wrap'}}>
                                            {
                                                variationColors.map((item,idx) => {
                                                    const allowSelection = selectedColor === item.variant 
                                                    ? true 
                                                    : selectedSize 
                                                        ? productVariations.map(v => { 
                                                            if (!v.variationStockable || !!v.stockOverride ? true : v.variationStock && v.variationStock > 0 ? true : false) {
                                                                return v
                                                            } 
                                                        }).filter(v => {
                                                            if(!v) return false
                                                            return v.colorHexcode === item.variant && 
                                                            v.variationSize === selectedSize                                                         
                                                        }).length 
                                                            ? true : false 
                                                        : true

                                                    return(
                                                        !!item.variant &&
                                                        <div key={idx} 
                                                            className='color-variation'
                                                            onClick={()=> {
                                                                if(!allowSelection) return null
                                                                const color = selectedColor === item.variant ? null : item.variant
                                                                setselectedColor(color)

                                                                const variation = color ? productVariations.filter(item => item.colorHexcode === color) : []
                                                                if(variation.length) {
                                                                    // setselectedVariation(variation[0])
                                                                    const imageIndex = productDetails.images.findIndex( elem => elem.ImageID === variation[0].ImageID)
                                                                    setinterval(color && imageIndex > -1 ? null : defaultCarouselInterval)
                                                                    setactiveIndex(color && imageIndex > -1 ? imageIndex : null)
                                                                } else {
                                                                    setinterval(defaultCarouselInterval)
                                                                    setactiveIndex(null)
                                                                }
                                                                
                                                                return
                                                            }}
                                                            style={{border: (selectedColor === item.variant) ? `0.1px solid ${styleGuide.color16}` : '0.1px solid transparent',}}
                                                        >
                                                            <div style={{width: 25, height: 25, backgroundColor: `${item.variant}`, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'}}>
                                                                { allowSelection ? <Tick fill={item?.variant?.toUpperCase() === "#FFFFFF" && styleGuide.color4}/> : <Close fill={item?.variant?.toUpperCase() === "#FFFFFF" ? styleGuide.color4 : styleGuide.color1} style={{width:9, height:9}}/>}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Grid>
                                }
                                {
                                    !!variationTitles.length && !variationColors.length && !variationSizes.length && <Grid item xs={12}>
                                        <CaptionText style={{color: styleGuide.color6}} translationKey="_PRODUCT_VARIATION_TITLE_LABEL_" fallbackText="Variationer" /> 
                                        <div style={{display: 'flex', flex: 0.5, gap: '0.5rem', marginTop: 10, flexWrap: 'wrap'}}>
                                            {
                                                variationTitles.map((item,idx) => 
                                                !!item.variant &&
                                                <div key={idx} 
                                                    className='title-variation'
                                                    onClick={()=> {
                                                        const title = selectedTitle === item.variant ? null : item.variant
                                                        setselectedTitle(title)
                                                        // setpauseCarousel(title ? true : false)
                                                        const variation = title ? productVariations.filter(item => item.variationTitle === title) : []
                                                        if(variation.length) {
                                                            // setselectedVariation(variation)
                                                            const imageIndex = productDetails.images.findIndex( elem => elem.ImageID === variation[0].ImageID)
                                                            setinterval(title && imageIndex > -1 && imageIndex !== activeIndex ? null : defaultCarouselInterval)
                                                            setactiveIndex(title && imageIndex > -1 ? imageIndex : null)
                                                        } else {
                                                            setinterval(defaultCarouselInterval)
                                                            setactiveIndex(null)
                                                        }
                                                        
                                                        
                                                        return
                                                    }}
                                                    style={{border: (selectedTitle === item.variant) ? `1px solid ${styleGuide.color4}` : '1px solid transparent',}}
                                                >
                                                    <CaptionText style={{color: styleGuide.color7}}>{item.variant}</CaptionText>
                                                </div>)
                                            }
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                        
                        <div style={{marginBottom: 10 }}>
                            {
                                !!selectedVariationStock 
                                ?   <ContentSmall skipTranslation={true} style={{ color: styleGuide.color6 }}>{selectedVariationStock}</ContentSmall>
                                :   <>&nbsp;</>
                            }
                        </div>
                        {!!productDetails.enableDiscount && <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20, gap: 10 }}>
                            <ContentNormal skipTranslation={true} style={{ color: styleGuide.color6, textDecoration: 'line-through'}}>{pointsConvertor(brandData,productDetails.productPointComputed, pointSuffix)}</ContentNormal>
                            <ContentNormal skipTranslation={true} style={{ color: styleGuide.color25,}}>Spar {pointsConvertor(brandData,productDetails.productPointComputed - productDetails.discountPointComputed, pointSuffix)}</ContentNormal>
                        </div>}
                        {brandData &&
                            brandData.features &&
                            brandData.features.retailPrice &&
                            productDetails &&
                            productDetails.retailPrice && (
                                <ContentNormal skipTranslation={true} style={{ color: styleGuide.color7, marginBottom: 20 }}>Vejl. udsalgspris: Kr. {productDetails.retailPrice} inkl. moms</ContentNormal>
                            )
                        }
                        <div style={{marginBottom: 20, display: 'flex', flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row',  gap: 20 }}>
                                <IconButtonLarge
                                    inverse={productDetails.reaction && productDetails.reaction.ReactionID && productDetails.reaction.ReactionID > 0}
                                    icon={<Heart2 fill='white' stroke={styleGuide.color7} strokeWidth="1.5px" />}
                                    onClick={() => productReaction(productDetails)}
                                />
                                {basketHasRoomforMoreProducts(basket, accessibleShops) ?
                                (brandData &&brandData.features && brandData.features.points === false ?
                                        <button 
                                        className={classes.InactiveButtonStyle}
                                        disabled={true} >
                                        <Cart1 fill='#000000' />
                                    </button> :
                                    <ButtonLarge1
                                        title={!!brandData?.features?.disablePrice ? null : translate("_ADD_TO_CART_BUTTON_TOOLTIP_", "Læg i kurv")}
                                        onClick={addToCart}
                                        icon={!!brandData?.features?.disablePrice ? null : <Cart1 fill={styleGuide.color1} style={{ marginLeft: '64px' }} />}
                                        fallbackText={!!brandData?.features?.disablePrice ? brandData?.features?.productButtonText[langCode] : !brandData?.features?.disablePrice && pointsConvertor(brandData,
                                            (productDetails.enableMulti && productDetails.minCount > 0) 
                                            ? `${productDetails.minCount * price}` 
                                            : `${price}`, pointSuffix)}
                                        />) :
                                    <ButtonInactive
                                    buttonText='Du kan ikke vælge flere'
                                    style={{ height: '45px', width: '80%' }}
                                    disabled={true} />}
                                <ProductSize />
                                <ProductVariation />
                            </div>
                            {!!taxProduct && <div style={{marginRight: 8, marginLeft: 64, marginBottom:16}}>
                                <ContentSmall style={{paddingLeft: 4, paddingRight: 4, fontStyle: 'italic'}} fallbackText={`${translate('_PRODUCT_TAXATION_TEXT_', 'Beskatning kr.')} ${pointsConvertor(brandData,taxProduct, '')}`} />
                            </div>}
                        </div>
                        {
                            !userData?.taxPercentage && <TaxPercentageForm />
                        }
                        {!!freightInfo?.enableFreightPriceInterval && <FreightInfo freightIntervals={freightInfo.freightIntervals} showDeliveryEstimate showAdditionalInfo showLoadFreight/>}
                        <div style={{marginBottom: 80}} />
                        <TabsUnstyled className='product-info-tabs-container' defaultValue={0}>
                            <TabsListUnstyled className='product-info-tabs-header'>
                                <TabUnstyled className={classes.TabStyle}>
                                    {translate('_PRODUCT_DESCRIPTION_TAB_CAPTION_', 'Beskrivelse')}
                                </TabUnstyled>
                                {/* <TabUnstyled className={classes.TabStyle} disabled>
                                    {translate('_PRODUCT_DETAILS_TAB_CAPTION_', 'Detaljer')}
                                </TabUnstyled> */}
                            </TabsListUnstyled>
                            <TabPanelUnstyled value={0} style={{ display: 'flex', flexDirection: 'column' }}>
                                <ContentNormal skipTranslation={true}>{viewMore ? parseLinks(productDescription) : parseLinks(_.truncate(productDescription, { length: summaryContentLength }))}</ContentNormal>
                                {
                                    !!productDescription && productDescription.length > summaryContentLength && 
                                    <ButtonMedium4
                                        style={{ alignSelf: 'center', marginTop: 60 }}
                                        fallbackText={viewMore ? translate('_READ_LESS_BUTTON_TEXT_', 'Læs mindre') : translate('_READ_MORE_BUTTON_TEXT_', 'Læs mere')}
                                        showIcon={false}
                                        onClick={() => setViewMore(!viewMore)}
                                    />
                                }
                            </TabPanelUnstyled>
                            <TabPanelUnstyled value={1}>{ }</TabPanelUnstyled>
                        </TabsUnstyled>
                        <BasketItemCard />
                    </div>
                </div>
            </div>
            {/* <div style={{ marginTop: contentContainerHeight - 100 }}> */}
            <div style={{ display: 'flex', width: '100%', height:productDetails.video ? videoHeight : null,flexDirection: 'column',marginTop:contentContainerHeight}}>
                {!!productDetails.video ?
                    <>
                    <div onClick={playButton}>
                        <div style={{height:productDetails.video ? videoHeight : null,position:'absolute', overflow: 'hidden',left:0,right:0}}>
                <video 
                    id='video-div'
                    playsInline
                    loop
                    preload="auto"
                    ref={videoRef}
                    style={{  
                        objectFit: 'fill',
                        width:'100%'
                        }} >
                        <source src={productDetails.video} />
                    </video>
                        </div>  
                        <div style={{height:productDetails.video ? videoHeight : null}} id="pause_button_time" className="play_pause_button blend" onClick={playButton}>
                            <h1 id="play_pause" style={{ position: "absolute"}}>{play ? <Playbutton style={{height:'100%',width:'100%'}} /> : <Pausebutton style={{height:'100%',width:'100%'}} />}</h1>
                        </div>
                    </div>
            </>
                : null}
            </div>
                <div className='product-recently-viewed' >
                    {
                        recentlyViewedProducts.length > 0 &&
                        <ScrollProductsSection
                            caption={translate("_RECENTLY_VIEWED_PRODUCTS_CAPTION_", 'Seneste kigget på')}
                            backgroundColor={styleGuide.color2}
                            sliderVariant='large'
                            productReaction={productReaction}
                            products={recentlyViewedProducts}
                            style={{
                                position: "relative",
                                paddingTop: 100,
                                paddingBottom: 100,
                                backgroundColor: styleGuide.color2,
                            }}
                            button={
                                <ButtonMedium4
                                    style={{ marginRight: '2rem', justifySelf: 'end' }}
                                    showIcon={false}
                                    translationKey="_HOME_POPULAR_PRODUCTS_SEE_MORE_BUTTON_"
                                    onClick={() => navigate(`/shop/${ShopID}`)}
                                >
                                    Se alle produkter
                                </ButtonMedium4>
                            }
                        />
                    }

                </div>
                <div className="product-related">
                    {
                        relatedProducts.length > 0 &&
                        <ScrollProductsSection
                            caption={translate("_FEATURED_PRODUCTS_CAPTION_", 'Fremhævede produkter')}
                            productReaction={productReaction}
                            products={relatedProducts}
                            style={{
                                position: "relative",
                                marginBottom: -100,
                                paddingTop: 100,
                                paddingBottom: 100,
                                backgroundColor: styleGuide.color3,
                            }}
                            button={
                                <ButtonMedium4
                                    style={{ marginRight: '2rem', justifySelf: 'end' }}
                                    showIcon={false}
                                    translationKey="_HOME_POPULAR_PRODUCTS_SEE_MORE_BUTTON_"
                                    onClick={() => navigate(`/shop/${ShopID}`)}
                                >
                                    Se alle produkter
                                </ButtonMedium4>
                            }
                        />
                    }
                </div>
        </div>
        <Modal show={showError} onHide={() => setShowError(false)} style={{ margin: 'auto' }} centered>
            <Modal.Header className="pb-0 border-0" closeButton style={{ fontSize: 9 }}>
                <Headline6 style={{ color: styleGuide.color13 }} translationKey="_ERROR_">Fejl</Headline6>
            </Modal.Header>
            <Modal.Body className="pt-4 pb-4">
                <p style={{ textAlign: 'center' }}>{errorMessage}</p>
            </Modal.Body>
        </Modal>
    </>
}

export default Product3


const ScrollProductsSection = (props) => {
    const { styleGuide, brandData, langCode } = useContext(AppContext)
    const classes = jss(styleGuide)
    const navigate = useNavigate()
    const { productReaction, products, sliderVariant, button, buttonLeft, buttonRight, backgroundColor, style, caption } = props
    const ProductComponent = sliderVariant && sliderVariant === 'large' ? ProductLarge : ProductNormal;
    const [scrollProducts, setScrollProducts] = useState(products || [])
    const pointSuffix = translate("_POINTS_SHORT_", "pt")
    const scrollProductsStyle = {
    };

    useEffect(() => {
        setScrollProducts(products)
    }, [products]);

    return !scrollProducts.length ? null : (
        <div className="horizontal-scroll-wrapper products-on-scroll" style={style}>
            <HorizontalScrollBlock
                caption={caption}
                button={button || null}
                buttonLeft={buttonLeft || null}
                buttonRight={buttonRight || null}
            >
                {scrollProducts.map((item) => {
                    const productTitle = !!item.translations &&
                    !!item.translations.length
                        ? item.translations.find(
                            (translation) => translation.languageKey === langCode
                        ) ? item.translations.find(
                            (translation) => translation.languageKey === langCode
                        ).elementTitle
                        : item.elementTitle
                    : item.elementTitle + (!!item.enableMulti ? ', ' + item.minCount + ' stk' : '')
                    return <ProductComponent
                        key={item.ProductID}
                        productID={item.ProductID}
                        showTag={item.enableDiscount ? true : false}
                        tagOptions={{
                            fallbackText: `${translate('_PRODUCT_DISCOUNT_TAG_TEXT_', 'Spar')} ${pointsConvertor(brandData,item.productPointComputed - item.discountPointComputed, pointSuffix)}`,
                            showClose: false,
                        }}
                        tagStyles={{ borderRadius: 8 }}
                        points={
                            (item.enableMulti && item.minCount > 0) 
                            ? `${item.discountPointComputed ? item.minCount * item.discountPointComputed : item.minCount * item.productPointComputed}` 
                            : `${item.discountPointComputed ? item.discountPointComputed : item.productPointComputed}`}
                        liked={item.reaction && item.reaction.ReactionID ? true : false}
                        className="productlarge item"
                        style={{ boxShadow: `0px 0px 3px 1px rgba(34, 34, 34, 0.15)` }}
                        text2={item.elementTitle && item.elementTitle.length > 20 ? `${item.elementTitle.slice(0, 20)}...` : item.elementTitle + (!!item.enableMulti ? ', ' + item.minCount + ' stk': '')}
                        text1={item.manufacturer}
                        wishlistCallback={() => productReaction(item)}
                        imageSrc={`url(${item.imageSrc})`}
                        imageBackgroundColor={item.imageBackgroundColor}
                    />
            })}
            </HorizontalScrollBlock>
        </div>
    );
};