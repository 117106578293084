import { AppContext } from 'contexts/AppContext';
import { useContext } from 'react'
import {createUseStyles} from 'react-jss'
import ExplainerText from 'components/elements/ExplainerText';
import Image from 'components/elements/Image';
import Video from 'components/elements/Video';
import Headline6 from 'components/elements/Headline6';
import { useNavigate } from 'react-router-dom';
import '../elements/css/Postcard.css'

const jss = createUseStyles({
    PostCardInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    '@media screen and (max-width: 768px)': {
        PostCardInfo: {
            flexDirection: 'column',
            justifyContent: 'center',
        },
    },
})

const postCardInfoHeight = 100

const PostCard = (props) => {
    const { styleGuide } = useContext(AppContext)
    const {
        title,
        description,
        imageSrc,
        imageStyles,
        titleStyles,
        descriptionStyles,
        type,
        videoLink,
        onClick
    } = props
    const showVideo = !!type && type.toLowerCase() === 'video' ? true : false
    const imageHeight = styleGuide.postCardInfoAsOverlay && !showVideo ? `100%` : `calc(100% - ${postCardInfoHeight}px)`
    const postCardInfoStrip = <PostCardInfo {...{ title, description, titleStyles, descriptionStyles, onClick}} />

    return (
        <div
            className={'postcardWrapper'}
            style={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                boxShadow: `0px 0px 4px ${styleGuide.color4}`,
                ...props.style
            }}
        >
            {
                showVideo 
                    ?   <Video 
                            url={videoLink} 
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 0,
                                height: imageHeight,
                                width: '100%',
                                backgroundColor: styleGuide.postCardInfoBackground,
                                ...imageStyles
                            }}
                        />
                    :   <Image
                            onClick={onClick}
                            src={imageSrc}
                            style={{
                                position: 'absolute',
                                cursor: 'pointer',
                                top: 0,
                                left: 0,
                                zIndex: 0,
                                height: imageHeight,
                                width: '100%',
                                backgroundColor: styleGuide.postCardInfoBackground,
                                backgroundSize:"100% 100%",
                                ...imageStyles
                            }}
                        >
                            
                            { !!styleGuide.productInfoAsOverlay ? postCardInfoStrip : null }
                        </Image>
            }
            
            { !styleGuide.productInfoAsOverlay || showVideo ? postCardInfoStrip : null }
        </div>
    )
}


const PostCardInfo = (props) => {
    const { styleGuide } = useContext(AppContext)
    const classes = jss(styleGuide)
    const {
        title,
        description,
        titleStyles,
        descriptionStyles,
        onClick
    } = props

    return (
        <div
        onClick={onClick}
        className={classes.PostCardInfo}
        style={{
            position: 'absolute',
            bottom: 0,
            zIndex: 1,
            width: '100%',
            background: styleGuide.postCardInfoBackground,
            display: 'flex',
            padding: '20px',
            height: 100,
            cursor: 'pointer'
        }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', width:'85%'}} >   
                <Headline6 style={{color: styleGuide.postCardInfoTitleColor, fontSize: 15, whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis", marginTop: 10, ...titleStyles}}>{title || ''}</Headline6>
                <ExplainerText style={{ color: styleGuide.postCardInfoDescriptionColor,whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis", ...descriptionStyles, lineHeight: "16px" }}>
                    { description?.replaceAll(/(<([^>]+)>)/gi, "") || '' }
                </ExplainerText>
            </div>
        </div>
    )
}

export default PostCard;