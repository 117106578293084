import React, { useContext, useState, useEffect } from "react";
import Headline2 from "components/elements/Headline2";
import { translate } from "utils/Translate";
import { useNavigate, useParams } from "react-router-dom";
import { callAPI } from "utils/API";
import { AppContext } from "contexts/AppContext";
import Image from "components/elements/Image";
import Heart2 from "components/icons/Heart2";
import IconButtonMedium from "components/elements/IconButtonMedium";
import ContentNormal from "components/elements/ContentNormal";
import ButtonMedium1 from "components/elements/ButtonMedium1";
import ExplainerTextTiny from "components/elements/ExplainerTextTiny";
import HorizontalScrollBlock from "components/blocks/horizontalScrollBlock/HorizontalScrollBlock";
import ProductNormal from "components/elements/ProductNormal";
import LabelTag1 from "components/elements/LabelTag1";
import { pointsConvertor } from "utils/general";
import GoToProductsBanner from "./GoToProductsBanner";
import ButtonLarge4 from "components/elements/ButtonLarge4";
import { createUseStyles } from "react-jss";

const jss = createUseStyles({
  wishlistMain: {
    marginTop: 200,
  },
  seeMoreButton: {
    position: "relative",
    marginTop: 30,
    alignSelf: "center"
  },
  "@media screen and (min-width: 768px)": {
    wishlistMain: {
      marginTop: 200,
    },
  },
  "@media screen and (min-width: 1280px)": {
    seeMoreButton: {
      position: "absolute",
      marginTop: 0,
      top: 15,
      right: 0,
    }
  },
});

const Wishlist = (props) => {
  const { styleGuide, brandData, userData, langCode } = useContext(AppContext);
  const { ShopID } = useParams();
  const [products, setProducts] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [points, setPoints] = useState(0);
  const [deficitPoints, setDeficitPoints] = useState(0);
  const [isDeficit, setIsDeficit] = useState(false);
  const [likedProducts, setLikedProducts] = useState([]);
  const [tagOptions, setTagOptions] = useState({});
  const navigate = useNavigate();
  const pointSuffix = translate("_POINTS_SHORT_", "pt")

  useEffect(() => {
    fetchWishlistProducts();
  }, [ShopID]);

  useEffect(() => {
    if (points != 0) {
      const deficitPts = isNaN(points)
        ? 0
        : points -
        (userData && !isNaN(userData.walletAmountPoints)
          ? Number(userData.walletAmountPoints)
          : 0);
      setDeficitPoints(deficitPts);
      setIsDeficit(deficitPts > 0 ? true : false);
    }
  }, [points]);

  useEffect(() => {
    if (products.length) {
      let liked = [];
      products.forEach((element) => {
        if (element?.reaction?.ReactionID) {
          liked.push(element.ProductID);
        }
      });
      setLikedProducts(liked);
    }
  }, [products]);

  const suggestedProductsStyle = {
    position: "relative",
  };

  const productReaction = async (product) => {
    const ReactionID = product.reaction ? product.reaction.ReactionID : null;
    const reaction = await callAPI("/product/reaction", "POST", {
      body: {
        ReactionTargetID: product.ProductID,
        reactionData: JSON.stringify({ ShopID: product.ShopID }),
        ...(ReactionID && { ReactionID }),
      },
    });

    if (reaction && reaction.affectedRows) {
      fetchWishlistProducts();
    }
  };

  const fetchWishlistProducts = async () => {
    let shopproducts = await callAPI(`product/wishlist`, "GET");
    if (shopproducts.length == 1 && shopproducts[0].images.length) {
      shopproducts[0].images.sort((a, b) => a.poolOrder - b.poolOrder);
      shopproducts[0].imageSrc = shopproducts[0].images[0].imageOriginSource;
      shopproducts[0].imageBackgroundColor = shopproducts[0].images[0].backgroundColor;
      setProducts(shopproducts);
      setImageUrl(shopproducts[0].imageSrc);
      setPoints(
        shopproducts[0].discountPointComputed
          ? shopproducts[0].discountPointComputed
          : shopproducts[0].productPointComputed
      );
      if (shopproducts[0].enableDiscount) {
        setTagOptions({
          fallbackText: `Spar ${pointsConvertor(brandData,
            shopproducts[0].productPointComputed -
            shopproducts[0].discountPointComputed
            , pointSuffix)}`,
          showClose: false,
        });
      }
    } else {
      setProducts(shopproducts);
    }
  };

  const product = products.length ? products[0] : null

  const productTitle = !!product && !!product.translations && product.translations.length > 0
    ? product.translations.find((translation) => translation.languageKey === langCode)
      ? product.translations.find((translation) => translation.languageKey === langCode).elementTitle
      : product.elementTitle
    : product?.elementTitle

  const productDescription = !!product && !!product.translations && product.translations.length > 0
    ? product.translations.find((translation) => translation.languageKey === langCode)
      ? product.translations.find((translation) => translation.languageKey === langCode).elementContent
      : product.elementContent
    : product?.elementContent

  const classes = jss(styleGuide);

  return (
    <div ref={props.wishlistRef}>
      {
        products.length <= 0 ?
          <GoToProductsBanner id="GoToProductsBanner" customClass={classes.wishlistMain} />
          :
          <div
            id="WishlistBlock"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              position: "relative"
            }}
            className={classes.wishlistMain}
          >
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Headline2
                translationKey="_PROFILE_PAGE_YOUR_WISHLIST_"
                fallbackText="Din ønskeliste"
              />
            </div>
            <div style={{ display: "flex", gap: "1rem" }}>
              {products.length === 1 ? (
                <div className="singleWishlistWrapper">
                  <div style={{ position: "absolute", top: 30, right: 30 }}>
                    <IconButtonMedium
                      inverse={true}
                      onClick={() => productReaction(product)}
                      icon={
                        <Heart2
                          fill="white"
                          style={{ height: 10, width: 10 }}
                          stroke={styleGuide.color7}
                          strokeWidth="1.5px"
                        />
                      }
                    />
                  </div>
                  <div className="singleWishlistImage">
                    <Image
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundSize: "contain",
                      }}
                      src={`url(${imageUrl})`}
                    >
                      {product.enableDiscount ? (
                        <div style={{ display: "flex", padding: "10px" }}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <LabelTag1
                              {...tagOptions}
                              className="lableTagButon"
                              style={{
                                alignSelf: "flex-start",
                                marginTop: "20px",
                                zIndex: 1,
                                borderRadius: 8,
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                    </Image>
                  </div>
                  <div className="singleWishListText">
                    <>
                      {product.manufacturer != "" ? (
                        <Headline2>{product.manufacturer}</Headline2>
                      ) : null}
                    </>

                    <text
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: 600,
                      }}
                    >
                      {productTitle + (!!product.enableMulti ? ', ' + product.minCount + ' stk' : '')}
                    </text>
                    <ContentNormal className="text">
                      {productDescription}
                    </ContentNormal>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <ButtonMedium1
                        showIcon={false}
                        style={{
                          maxWidth: 100,
                        }}
                        onClick={() => navigate(`/product/${product.ProductID}`)}
                      >
                        {`${pointsConvertor(brandData, points, pointSuffix)}`}
                      </ButtonMedium1>
                      {isDeficit && (
                        <div style={{ textAlign: "center", padding: "5px 10px" }}>
                          <ExplainerTextTiny
                            style={{ verticalAlign: "middle" }}
                            fallbackText={`${translate(
                              "_USER_POINT_DEFICIENT_FOR_PRODUCT_TEXT_",
                              "Du mangler"
                            )} ${deficitPoints} ${translate("_POINTS_", "point")}`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {products.length > 3 ? (
                    <div
                      className="horizontal-scroll-wrapper productsLimited3"
                      style={suggestedProductsStyle}
                    >
                      <HorizontalScrollBlock>
                        {products.map((item) => (
                          <ProductNormal
                            productID={item.ProductID}
                            key={item.ProductID}
                            showTag={item.enableDiscount ? true : false}
                            tagOptions={{
                              fallbackText: `${translate(
                                "_PRODUCT_DISCOUNT_TAG_TEXT_",
                                "Spar"
                              )} ${pointsConvertor(brandData, item.productPointComputed - item.discountPointComputed, pointSuffix)}`,
                              showClose: false,
                            }}
                            tagStyles={{ borderRadius: 8 }}
                            points={(item.enableMulti && item.minCount > 0)
                              ? `${item.discountPointComputed ? item.minCount * item.discountPointComputed : item.minCount * item.productPointComputed}`
                              : `${item.discountPointComputed ? item.discountPointComputed : item.productPointComputed}`}
                            liked={
                              ShopID
                                ? likedProducts.includes(item.ProductID)
                                  ? true
                                  : false
                                : true
                            }
                            className="productlarge item"
                            style={{
                              boxShadow: `0px 0px 3px 1px rgba(34, 34, 34, 0.15)`,
                            }}
                            text2={item.elementTitle + (!!item.enableMulti ? ', ' + item.minCount + ' stk' : '')}
                            text1={item.manufacturer}
                            wishlistCallback={() => productReaction(item)}
                            imageSrc={`url(${item.images[0].imageOriginSource})`}
                            imageBackgroundColor={item.imageBackgroundColor}
                            outOfStock={
                              item.variations?.length &&
                              !item.variations[0].stockOverride &&
                              item.variations[0].variationStockable &&
                              item.variations[0].variationStock <= 0
                            }
                            activityName='profile'
                          />
                        ))}
                      </HorizontalScrollBlock>
                    </div>
                  ) : (
                    <div className="productsLimited3" style={suggestedProductsStyle}>
                      {products.map((item) => (
                        <ProductNormal
                          productID={item.ProductID}
                          key={item.ProductID}
                          showTag={item.enableDiscount ? true : false}
                          tagOptions={{
                            fallbackText: `${translate(
                              "_PRODUCT_DISCOUNT_TAG_TEXT_",
                              "Spar"
                            )} ${item.productPointComputed - item.discountPointComputed
                              } ${pointSuffix}`,
                            showClose: false,
                          }}
                          tagStyles={{ borderRadius: 8 }}
                          points={(item.enableMulti && item.minCount > 0)
                            ? `${item.discountPointComputed ? item.minCount * item.discountPointComputed : item.minCount * item.productPointComputed}`
                            : `${item.discountPointComputed ? item.discountPointComputed : item.productPointComputed}`}
                          liked={
                            ShopID
                              ? likedProducts.includes(item.ProductID)
                                ? true
                                : false
                              : true
                          }
                          className="productlarge item"
                          style={{
                            boxShadow: `0px 0px 3px 1px rgba(34, 34, 34, 0.15)`,
                          }}
                          text2={item.elementTitle + (!!item.enableMulti ? ', ' + item.minCount + ' stk' : '')}
                          text1={item.manufacturer}
                          wishlistCallback={() => productReaction(item)}
                          imageSrc={`url(${item.images[0].imageOriginSource})`}
                          imageBackgroundColor={item.imageBackgroundColor}
                          outOfStock={
                            item.variations?.length &&
                            !item.variations[0].stockOverride &&
                            item.variations[0].variationStockable &&
                            item.variations[0].variationStock <= 0
                          }
                          activityName='profile'
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={classes.seeMoreButton}>
              <ButtonLarge4
                showIcon={false}
                translationKey="_PROFILE_PAGE_SEE_ALL_PRODUCTS_"
                fallbackText="Se alle produkter"
                onClick={() => navigate(`/shop/${props.defaultShopID}`)}
              />
            </div>
          </div>
      }
    </div>
  )
};
export default Wishlist;
