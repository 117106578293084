const productBoxDimensionRatio = 1.67 // = (527 / 315)

export const definedStyles = {
    ThemeONE : {
        fontFamily: `'Poppins', sans-serif`,
        secondaryFontFamily: `'Poppins', sans-serif`,

        headerHeight: 100,

        headline1FontSize: 50,
        headline1LineHeight: '64px',
        headline1FontWeight: 600,

        headline2FontSize: 40,
        headline2LineHeight: '54px',
        headline2FontWeight: 600,

        headline3FontSize: 30,
        headline3LineHeight: '44px',
        headline3FontWeight: 600,

        headline4FontSize: 24,
        headline4LineHeight: '26px',
        headline4FontWeight: 600,

        headline5FontSize: 18,
        headline5LineHeight: '28px',
        headline5FontWeight: 600,

        headline6FontSize: 16,
        headline6LineHeight: '22px',
        headline6FontWeight: 600,

        captionFontSize: 12,
        captionLineHeight: '16px',
        captionFontWeight: 600,

        contentLargeFontSize: 20,
        contentLargeLineHeight: '30px',
        contentLargeFontWeight: 400,

        contentNormalFontSize: 16,
        contentNormalLineHeight: '24px',
        contentNormalFontWeight: 400,

        contentSmallFontSize: 12,
        contentSmallLineHeight: '18px',
        contentSmallFontWeight: 400,

        explainerTextFontSize: 12,
        explainerTextLineHeight: '12px',
        explainerTextFontWeight: 400,

        explainerTextTinyFontSize: 10,
        explainerTextTinyLineHeight: '10px',
        explainerTextTinyFontWeight: 400,

        quoteLargeFontSize: 20,
        quoteLargeLineHeight: '30px',
        quoteLargeFontWeight: 400,

        quoteNormalFontSize: 16,
        quoteNormalLineHeight: '24px',
        quoteNormalFontWeight: 400,

        color1: '#FFFFFF', // white
        color2: '#F0F0F0', // grayLightest
        color3: '#E8E8E8', // grayLighter
        color4: '#BFBFBF', // grayLight - border
        color5: '#999999', // gray
        color6: '#545454', // grayDark
        color7: '#222222', // grey - darkest
        color8: '#28C262',
        color9: '#1D8F48',
        color10: '#F2D835',
        color11: '#D9C130',
        color12: '#FF4747', // icon button hover bg
        color13: '#CC3939',
        color14: '#383693', // primary 3 - overriden by primaryColor1
        color15: '#4A48C4', // primary 4 - overriden by primaryColor2
        color16: '#E56B2E', // primary 1 - overriden by secondaryColor1
        color17: '#B25324', // primary 2 - overriden by secondaryColor2
        color18: '#000000', // black
        color19: '#7FA894', // primary 3
        color19: '#f3c13a', // Yellow
        color20: '#f5c6cb', // Error color border
        color21: '#f8d7da', // Error color background
        color22: '#721c24', // error color text
        color23: '#c3e6cb', // Success color border
        color24: '#d4edda', // Success color background
        color25: '#155724', // Success color text
        color26: '#27454b', // primary 5 - overriden by tertiaryColor1
        
        buttonBorderRadius: 14,
        selectDropdownBorderRadius: 0,
        
        buttonLargeHeight: 45,
        buttonLargeFontSize: 14,
        buttonLargeLineHeight: '24px',

        buttonMediumHeight: 35,
        buttonMediumFontSize: 12,
        buttonMediumLineHeight: '22px',
        
        roundButtonSize: 40,

        labelTagHeight: 34,
        labelTagFontSize: 14,
        labelTagLineHeight: '24px',

        productLargeHeight: 400 * productBoxDimensionRatio, //600,
        productLargeWidth: 400, //493,

        productLargeHeightTab: 300 * productBoxDimensionRatio, //527,
        productLargeWidthTab: 300, //375,

        productLargeHeightMobile: 300 * productBoxDimensionRatio, //527,
        productLargeWidthMobile: 300, //375,

        productNormalHeight: 309 * productBoxDimensionRatio, //453,
        productNormalWidth: 309, //387,

        productNormalHeightTab: 500, //422,
        productNormalWidthTab: 300, //252,

        productNormalHeightMobile: 550, //422, ratio = 1.67
        productNormalWidthMobile: 315, //252,

        productInfoAsOverlay: false,
        productInfoBackground: `rgba(255, 255, 255, 1)`,
        productInfoText1Color: '#222222',
        productInfoText2Color: '#545454',
        productImageBackgroundColor: '#efefef',

        postCardHeight: 380,//377, 
        postCardWidth: 520, //600,

        postCardHeightTab: 420, //433,
        postCardWidthTab: 620, //648,

        postCardHeightMobile: 271, //271,
        postCardWidthMobile: 300, //315,

        postCardInfoAsOverlay: false,
        postCardInfoBackground: `rgba(34, 34, 34, 1)`,
        postCardInfoTitleColor: '#FFFFFF',
        postCardInfoDescriptionColor: '#FFFFFF',
        postCardInfoDateColor: '#BFBFBF',

        bannerMaxHeight: '50vh'
    },
    ThemeTWO: {
        fontFamily: `'Lora', serif`,
        secondaryFontFamily: `'Work Sans', sans-serif`,

        headerHeight: 180,

        headline1FontSize: 50,
        headline1LineHeight: '64px',
        headline1FontWeight: 400,

        headline2FontSize: 40,
        headline2LineHeight: '54px',
        headline2FontWeight: 400,

        headline3FontSize: 30,
        headline3LineHeight: '44px',
        headline3FontWeight: 400,

        headline4FontSize: 24,
        headline4LineHeight: '26px',
        headline4FontWeight: 400,

        headline5FontSize: 18,
        headline5LineHeight: '28px',
        headline5FontWeight: 400,

        headline6FontSize: 16,
        headline6LineHeight: '22px',
        headline6FontWeight: 600,

        captionFontSize: 12,
        captionLineHeight: '16px',
        captionFontWeight: 600,

        contentLargeFontSize: 20,
        contentLargeLineHeight: '30px',
        contentLargeFontWeight: 400,

        contentNormalFontSize: 16,
        contentNormalLineHeight: '24px',
        contentNormalFontWeight: 400,

        contentSmallFontSize: 12,
        contentSmallLineHeight: '18px',
        contentSmallFontWeight: 400,

        explainerTextFontSize: 12,
        explainerTextLineHeight: '12px',
        explainerTextFontWeight: 400,

        explainerTextTinyFontSize: 10,
        explainerTextTinyLineHeight: '10px',
        explainerTextTinyFontWeight: 400,

        quoteLargeFontSize: 20,
        quoteLargeLineHeight: '30px',
        quoteLargeFontWeight: 400,

        quoteNormalFontSize: 16,
        quoteNormalLineHeight: '24px',
        quoteNormalFontWeight: 400,

        color1: '#FFFFFF', // white
        color2: '#F0F0F0',
        color3: '#E8E8E8',
        color4: '#BFBFBF', // grey - border
        color5: '#999999',
        color6: '#545454',
        color7: '#222222', // grey - darkest
        color8: '#28C262',
        color9: '#1D8F48',
        color10: '#F2D835',
        color11: '#D9C130',
        color12: '#FF4747', // icon button hover bg
        color13: '#CC3939',
        color14: '#7FA894', // primary 3
        color15: '#597568', // primary 4
        color16: '#2B3D52', // primary 1
        color17: '#466385', // primary 2
        color18: '#000000', // black
        color19: '#f3c13a', // Yellow
        color20: '#f5c6cb', // Error color border
        color21: '#f8d7da', // Error color background
        color22: '#721c24', // error color text
        color23: '#c3e6cb', // Success color border
        color24: '#d4edda', // Success color background
        color25: '#155724', // Success color text
        color26: '#27454b', // primary 5 - overriden by tertiaryColor1

        buttonBorderRadius: 0,
        selectDropdownBorderRadius: 0,

        buttonLargeHeight: 45,
        buttonLargeFontSize: 14,
        buttonLargeLineHeight: '24px',

        buttonMediumHeight: 35,
        buttonMediumFontSize: 12,
        buttonMediumLineHeight: '22px',
        
        roundButtonSize: 40,

        labelTagHeight: 34,
        labelTagFontSize: 14,
        labelTagLineHeight: '24px',

        productLargeHeight: 340.12 * productBoxDimensionRatio, //600,
        productLargeWidth: 400, //493,

        productLargeHeightTab: 240.12 * productBoxDimensionRatio, //527,
        productLargeWidthTab: 300, //375,

        productLargeHeightMobile: 240.12 * productBoxDimensionRatio, //527,
        productLargeWidthMobile: 300, //375,

        productNormalHeight: 249.12 * productBoxDimensionRatio, //453,
        productNormalWidth: 309, //387,

        productNormalHeightTab: 400, //527,
        productNormalWidthTab: 300, //315,

        productNormalHeightMobile: 450, //527,
        productNormalWidthMobile: 315, //315,

        productInfoAsOverlay: true,
        productInfoBackground: `linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, rgba(34, 34, 34, 0.6) 100%)`,
        productInfoText1Color: '#FFFFFF',
        productInfoText2Color: '#FFFFFF',
        productImageBackgroundColor: `linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, rgba(34, 34, 34, 0.6) 100%)`,

        postCardHeight: 377, //433,
        postCardWidth: 520, //600,

        postCardHeightTab: 377, //433,
        postCardWidthTab: 620, //648,

        postCardHeightMobile: 271, //271,
        postCardWidthMobile: 300, //315,
        
        postCardInfoAsOverlay: true,
        postCardInfoBackground: `rgba(34, 34, 34, 0.5)`,
        postCardInfoTitleColor: '#FFFFFF',
        postCardInfoDescriptionColor: '#FFFFFF',
        postCardInfoDateColor: '#BFBFBF',
        bannerMaxHeight: '50vh'

    },
    ThemeTHREE: {
        // fontFamily: `'Dancing Script', cursive`,
        fontFamily: `'Barlow', sans-serif`,
        secondaryFontFamily: `'Montserrat', sans-serif`,

        headerHeight: 100,

        headline1FontSize: 50,
        headline1LineHeight: '64px',
        headline1FontWeight: 600,

        headline2FontSize: 40,
        headline2LineHeight: '54px',
        headline2FontWeight: 600,

        headline3FontSize: 30,
        headline3LineHeight: '44px',
        headline3FontWeight: 600,

        headline4FontSize: 24,
        headline4LineHeight: '26px',
        headline4FontWeight: 600,

        headline5FontSize: 18,
        headline5LineHeight: '28px',
        headline5FontWeight: 600,

        headline6FontSize: 16,
        headline6LineHeight: '22px',
        headline6FontWeight: 600,

        captionFontSize: 12,
        captionLineHeight: '16px',
        captionFontWeight: 600,

        contentLargeFontSize: 20,
        contentLargeLineHeight: '30px',
        contentLargeFontWeight: 400,

        contentNormalFontSize: 16,
        contentNormalLineHeight: '24px',
        contentNormalFontWeight: 400,

        contentSmallFontSize: 12,
        contentSmallLineHeight: '18px',
        contentSmallFontWeight: 400,

        explainerTextFontSize: 12,
        explainerTextLineHeight: '12px',
        explainerTextFontWeight: 400,

        explainerTextTinyFontSize: 10,
        explainerTextTinyLineHeight: '10px',
        explainerTextTinyFontWeight: 400,

        quoteLargeFontSize: 20,
        quoteLargeLineHeight: '30px',
        quoteLargeFontWeight: 400,

        quoteNormalFontSize: 16,
        quoteNormalLineHeight: '24px',
        quoteNormalFontWeight: 400,

        color1: '#FFFFFF', // white
        color2: '#F0F0F0',
        color3: '#E8E8E8',
        color4: '#BFBFBF', // grey - border
        color5: '#999999',
        color6: '#545454',
        color7: '#222222', // grey - darkest
        color8: '#28C262',
        color9: '#1D8F48',
        color10: '#F2D835',
        color11: '#D9C130',
        color12: '#FF4747', // icon button hover bg
        color13: '#CC3939',
        color14: '#F2DFD8', // primary 3
        color15: '#D9C8C1', // primary 4
        color16: '#FF906C', // primary 1
        color17: '#CC7356', // primary 2
        color18: '#000000', // black
        color19: '#f3c13a', // Yellow
        color20: '#f5c6cb', // Error color border
        color21: '#f8d7da', // Error color background
        color22: '#721c24', // error color text
        color23: '#c3e6cb', // Success color border
        color24: '#d4edda', // Success color background
        color25: '#155724', // Success color text
        color26: '#27454b', // primary 5 - overriden by tertiaryColor1

        buttonBorderRadius: 34,
        selectDropdownBorderRadius: 20,

        buttonLargeHeight: 45,
        buttonLargeFontSize: 14,
        buttonLargeLineHeight: '24px',

        buttonMediumHeight: 35,
        buttonMediumFontSize: 12,
        buttonMediumLineHeight: '22px',
        
        roundButtonSize: 40,

        labelTagHeight: 34,
        labelTagFontSize: 14,
        labelTagLineHeight: '24px',

        productLargeHeight: 340.12 * productBoxDimensionRatio, //600,
        productLargeWidth: 400, //493,

        productLargeHeightTab: 240.12 * productBoxDimensionRatio, //527,
        productLargeWidthTab: 300, //375,

        productLargeHeightMobile: 240.12 * productBoxDimensionRatio, //527,
        productLargeWidthMobile: 300, //375,

        productNormalHeight: 249.12 * productBoxDimensionRatio, //453,
        productNormalWidth: 309, //387,

        productNormalHeightTab: 400, //527,
        productNormalWidthTab: 300, //315,

        productNormalHeightMobile: 450, //527,
        productNormalWidthMobile: 315, //315,

        productInfoAsOverlay: true,
        productInfoBackground: `rgba(255, 255, 255, 0.8)`,
        productInfoText1Color: '#222222',
        productInfoText2Color: '#545454',
        productImageBackgroundColor: `rgba(255, 255, 255, 0.8)`,

        postCardHeight: 377, //433,
        postCardWidth: 520, //600,

        postCardHeightTab: 377, //433,
        postCardWidthTab: 620, //648,

        postCardHeightMobile: 271, //271,
        postCardWidthMobile: 300, //315,

        postCardInfoAsOverlay: true,
        postCardInfoBackground: `rgba(255, 255, 255, 0.8)`,
        postCardInfoTitleColor: '#222222',
        postCardInfoDescriptionColor: '#222222',
        postCardInfoDateColor: '#999999',
        bannerMaxHeight: '50vh'

    },
    ThemeFOUR : {
        fontFamily: `'Poppins', sans-serif`,
        secondaryFontFamily: `'Poppins', sans-serif`,

        headerHeight: 100,

        headline1FontSize: 50,
        headline1LineHeight: '64px',
        headline1FontWeight: 600,

        headline2FontSize: 40,
        headline2LineHeight: '54px',
        headline2FontWeight: 600,

        headline3FontSize: 30,
        headline3LineHeight: '44px',
        headline3FontWeight: 600,

        headline4FontSize: 24,
        headline4LineHeight: '26px',
        headline4FontWeight: 600,

        headline5FontSize: 18,
        headline5LineHeight: '28px',
        headline5FontWeight: 600,

        headline6FontSize: 16,
        headline6LineHeight: '22px',
        headline6FontWeight: 600,

        captionFontSize: 12,
        captionLineHeight: '16px',
        captionFontWeight: 600,

        contentLargeFontSize: 20,
        contentLargeLineHeight: '30px',
        contentLargeFontWeight: 400,

        contentNormalFontSize: 16,
        contentNormalLineHeight: '24px',
        contentNormalFontWeight: 400,

        contentSmallFontSize: 12,
        contentSmallLineHeight: '18px',
        contentSmallFontWeight: 400,

        explainerTextFontSize: 12,
        explainerTextLineHeight: '12px',
        explainerTextFontWeight: 400,

        explainerTextTinyFontSize: 10,
        explainerTextTinyLineHeight: '10px',
        explainerTextTinyFontWeight: 400,

        quoteLargeFontSize: 20,
        quoteLargeLineHeight: '30px',
        quoteLargeFontWeight: 400,

        quoteNormalFontSize: 16,
        quoteNormalLineHeight: '24px',
        quoteNormalFontWeight: 400,

        color1: '#FFFFFF', // white
        color2: '#F0F0F0', // grayLightest
        color3: '#E8E8E8', // grayLighter
        color4: '#BFBFBF', // grayLight - border
        color5: '#999999', // gray
        color6: '#545454', // grayDark
        color7: '#222222', // grey - darkest
        color8: '#28C262',
        color9: '#1D8F48',
        color10: '#F2D835',
        color11: '#D9C130',
        color12: '#FF4747', // icon button hover bg
        color13: '#CC3939',
        color14: '#383693', // primary 3 - overriden by primaryColor1
        color15: '#4A48C4', // primary 4 - overriden by primaryColor2
        color16: '#E56B2E', // primary 1 - overriden by secondaryColor1
        color17: '#B25324', // primary 2 - overriden by secondaryColor2
        color18: '#000000', // black
        color19: '#7FA894', // primary 3
        color19: '#f3c13a', // Yellow
        color20: '#f5c6cb', // Error color border
        color21: '#f8d7da', // Error color background
        color22: '#721c24', // error color text
        color23: '#c3e6cb', // Success color border
        color24: '#d4edda', // Success color background
        color25: '#155724', // Success color text
        color26: '#27454b', // primary 5 - overriden by tertiaryColor1
        
        buttonBorderRadius: 14,
        selectDropdownBorderRadius: 0,
        
        buttonLargeHeight: 45,
        buttonLargeFontSize: 14,
        buttonLargeLineHeight: '24px',

        buttonMediumHeight: 35,
        buttonMediumFontSize: 12,
        buttonMediumLineHeight: '22px',
        
        roundButtonSize: 40,

        labelTagHeight: 34,
        labelTagFontSize: 14,
        labelTagLineHeight: '24px',

        productLargeHeight: 400 * productBoxDimensionRatio, //600,
        productLargeWidth: 400, //493,

        productLargeHeightTab: 300 * productBoxDimensionRatio, //527,
        productLargeWidthTab: 300, //375,

        productLargeHeightMobile: 300 * productBoxDimensionRatio, //527,
        productLargeWidthMobile: 300, //375,

        productNormalHeight: 309 * productBoxDimensionRatio, //453,
        productNormalWidth: 309, //387,

        productNormalHeightTab: 500, //422,
        productNormalWidthTab: 300, //252,

        productNormalHeightMobile: 550, //422, ratio = 1.67
        productNormalWidthMobile: 315, //252,

        productInfoAsOverlay: false,
        productInfoBackground: `rgba(255, 255, 255, 1)`,
        productInfoText1Color: '#222222',
        productInfoText2Color: '#545454',
        productImageBackgroundColor: '#efefef',

        postCardHeight: 380,//377, 
        postCardWidth: 520, //600,

        postCardHeightTab: 420, //433,
        postCardWidthTab: 620, //648,

        postCardHeightMobile: 271, //271,
        postCardWidthMobile: 300, //315,

        postCardInfoAsOverlay: false,
        postCardInfoBackground: `rgba(34, 34, 34, 1)`,
        postCardInfoTitleColor: '#FFFFFF',
        postCardInfoDescriptionColor: '#FFFFFF',
        postCardInfoDateColor: '#BFBFBF',
        bannerMaxHeight: '50vh'
    },
}
