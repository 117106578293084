import { Save } from "@material-ui/icons"
import ContentSmall from "components/elements/ContentSmall"
import IconButtonMedium from "components/elements/IconButtonMedium"
import { AppContext } from "contexts/AppContext"
import { useContext, useRef } from "react"
import { callAPI } from "utils/API"
import { translate } from "utils/Translate"

export const TaxPercentageForm = (props) => {
    const {overrideTranslationKey} = props
    const { styleGuide, userData, setUserData, brandData } = useContext(AppContext)
    const TaxRef = useRef()
    const showForm = !!brandData?.features?.enableWalletActivation && !brandData?.features?.disableWalletActivationGridSection
    const submitTaxPercentage = async() => {
        if(!TaxRef?.current?.value) return
        const taxPercentage = TaxRef?.current?.value
        const response = await callAPI("/user/saveTaxPercentage", "POST", { body: { taxPercentage: taxPercentage } })
        if (response) {
            setUserData({ ...userData, taxPercentage: taxPercentage })
        }    
    }

    return !showForm ? null : <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <ContentSmall
            style={{ fontStyle: "italic" }} 
            fallbackText={`${translate(
                overrideTranslationKey || '_PRODUCT_SCREEN_UPDATE_TAX_PERCENTAGE_TEXT_', 
                'Indtast din trækprocent for at se prisen efter skat i kroner'
            )}`} />            
        <input ref={TaxRef} style={{ width: 50, outline: 'none', padding: 5, paddingLeft: 14, borderRadius: styleGuide.buttonBorderRadius, border: `1px solid ${styleGuide.color4}` }} defaultValue={userData?.taxPercentage || ""} />
        <ContentSmall fallbackText="%" style={{ fontStyle: "italic" }} />
        <IconButtonMedium
            title="Gem"
            style={{ marginLeft: 8, width: 40 }}
            onClick={submitTaxPercentage}
            icon={
                <Save
                    fill='white'
                    style={{ height: 20, width: 20 }}
                    stroke={styleGuide.color16}
                    strokeWidth="1.5px"
                />
            }
        />
    </div>
}